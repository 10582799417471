<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card>
          <v-data-table
            :headers="items.Header"
            :items="items.Data"
            class="elevation-1"
          >
            <template v-slot:item.action="{ item }">
              <v-btn fab dark small color="green" @click="Prints(item)">
                <v-icon dark>fa fa-print</v-icon>
              </v-btn>
              <v-btn fab dark small color="red" @click="Delete(item)">
                <v-icon dark>fa fa-trash-alt</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      items: {
        header: [],
        data: [],
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.Select();
    });
  },
  methods: {
    Select() {
      axios
        .get(`/api/v1/Select/History/Setting?Type=${this.$route.query.Type}`)
        .then((res) => {
          if (res.status == 200) {
            this.items = res.data;
          }
        });
    },
    Delete(v) {
      this.PassAccept("delete", () => {
        axios
          .delete(
            `/api/v1/Delete/DeleteHistory/Setting?Id=${v.Id}&Type=${this.$route.query.Type}`
          )
          .then((res) => {
            if (res.status == 200) {
              this.Select();
            }
          });
      });
    },
    Prints(v) {
      if (this.$route.query.Type == "Widen") {
        axios.get(`/api/v1/FinanceWiden/pdf?Id=${v.Id}`).then((res) => {
          if (res.status == 200) {
            this.PrintJs({
              printable: res.data.base64,
              type: "pdf",
              base64: true,
            });
          }
        });
      } else if (this.$route.query.Type == "WidenEmp") {
        axios.get(`/api/v1/FinanceWidenEMP/pdf?Id=${v.Id}`).then((res) => {
          if (res.status == 200) {
            this.PrintJs({
              printable: res.data.base64,
              type: "pdf",
              base64: true,
            });
          }
        });
      }else if (this.$route.query.Type == "Lend") {
        axios.get(`/api/v1/FinanceLend/pdf?Id=${v.Id}`).then((res) => {
          if (res.status == 200) {
            this.PrintJs({
              printable: res.data.base64,
              type: "pdf",
              base64: true,
            });
          }
        });
      }
      else if (this.$route.query.Type == "Wage") {
        axios.get(`/api/v1/FinanceWage/pdf?Id=${v.Id}`).then((res) => {
          if (res.status == 200) {
            this.PrintJs({
              printable: res.data.base64,
              type: "pdf",
              base64: true,
            });
          }
        });
      }
      else if (this.$route.query.Type == "Receive") {
        axios.get(`/api/v1/FinanceReceive/pdf?Id=${v.Id}`).then((res) => {
          if (res.status == 200) {
            this.PrintJs({
              printable: res.data.base64,
              type: "pdf",
              base64: true,
            });
          }
        });
      }
      else if (this.$route.query.Type == "Money") {
        axios.get(`/api/v1/FinanceMoney/pdf?Id=${v.Id}`).then((res) => {
          if (res.status == 200) {
            this.PrintJs({
              printable: res.data.base64,
              type: "pdf",
              base64: true,
            });
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.ww {
  width: 40%;

  display: inline-block;
}
</style>
